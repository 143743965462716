export default {
  commons: {
    comment: '评论',
    initial: '初始化',
    examples: '示例',
    help_documentation: '帮助文档',
    delete_cancelled: '已取消删除',
    workspace: '工作空间',
    organization: '组织',
    setting: '设置',
    project: '项目',
    about_us: '关于',
    current_project: '当前项目',
    name: '名称',
    description: '描述',
    annotation: '注释',
    clear: '清空',
    save: '保存',
    update: '更新',
    save_success: '保存成功',
    delete_success: '删除成功',
    copy_success: '复制成功',
    modify_success: '修改成功',
    delete_cancel: '已取消删除',
    confirm: '确定',
    cancel: '取消',
    prompt: '提示',
    operating: '操作',
    input_limit: '长度在 {0} 到 {1} 个字符',
    login: '登录',
    welcome: '请输入用户名和密码登录qamp',
    username: '姓名',
    password: '密码',
    input_username: '请输入用户姓名',
    input_password: '请输入密码',
    test: '测试',
    create_time: '创建时间',
    update_time: '更新时间',
    add: '添加',
    member: '成员',
    email: '邮箱',
    phone: '电话',
    role: '角色',
    personal_info: '个人信息',
    api_keys: 'API Keys',
    quota: '配额管理',
    status: '状态',
    show_all: '显示全部',
    show: '显示',
    report: '报告',
    user: '用户',
    system: '系统',
    personal_setting: '个人设置',
    test_resource_pool: '测试资源池',
    system_setting: '系统设置',
    api: '接口用例',
    auto: '自动化测试',
    performance: '性能用例',
    functional: '功能用例',
    safety: '安全用例',
    exception: '异常用例',
    highUse: '部署用例',
    nouse: '无效用例',
    input_content: '请输入内容',
    create: '新建',
    edit: '编辑',
    copy: '复制',
    refresh: '刷新',
    remark: '描述',
    tags:'标签',
    tag_tip: '输入回车添加标签',
    delete: '删除',
    not_filled: '未填写',
    please_select: '请选择',
    search_by_name: '根据名称搜索',
    search_by_name_or_id: '根据ID或名称搜索',
    search_by_jira_key: '根据jiraKey搜索',
    search_by_sprint_name: '根据Sprint名称搜索',
    personal_information: '个人信息',
    exit_system: '退出系统',
    verification: '验证',
    title: '标题',
    custom: '自定义',
    select_date: '选择日期',
    calendar_heatmap: '测试日历',
    months_1: '一月',
    months_2: '二月',
    months_3: '三月',
    months_4: '四月',
    months_5: '五月',
    months_6: '六月',
    months_7: '七月',
    months_8: '八月',
    months_9: '九月',
    months_10: '十月',
    months_11: '十一月',
    months_12: '十二月',
    weeks_0: '周日',
    weeks_1: '周一',
    weeks_2: '周二',
    weeks_3: '周三',
    weeks_4: '周四',
    weeks_5: '周五',
    weeks_6: '周六',
    test_unit: '测试',
    system_parameter_setting: '系统参数设置',
    connection_successful: '连接成功',
    connection_failed: '连接失败',
    save_failed: '保存失败',
    host_cannot_be_empty: '主机不能为空',
    port_cannot_be_empty: '端口号不能为空',
    account_cannot_be_empty: '帐户不能为空',
    remove: '移除',
    remove_cancel: '移除取消',
    remove_success: '移除成功',
    tips: '认证信息已过期，请重新登录',
    not_performed_yet: '尚未执行',
    incorrect_input: '输入内容不正确',
    delete_confirm: '请输入以下内容，确认删除：',
    duplicate: '请选择目标阶段',
    login_username: 'ID 或 邮箱',
    input_login_username: '请输入用户 ID 或 邮箱',
    input_name: '请输入名称',
    please_upload: '请上传文件',
    formatErr: '格式错误',
    please_save: '请先保存',
    reference_documentation: "参考文档",
    id: 'ID',
    millisecond: '毫秒',
    cannot_be_null: '不能为空',
    already_exists: '名称不能重复',
    date: {
      select_date: '选择日期',
      start_date: '开始日期',
      end_date: '结束日期',
      select_date_time: '选择日期时间',
      start_date_time: '开始日期时间',
      end_date_time: '结束日期时间',
      range_separator: "至",
      data_time_error: "开始日期不能大于结束日期",
    },
    trigger_mode: {
      name: "触发方式",
      manual: "手动触发",
      schedule: "定时任务",
      api: "API调用"
    },
    adv_search: {
      title: '高级搜索',
      combine: '组合查询',
      test: "所属测试",
      project: "所属项目",
      search: "查询",
      reset: "重置",
      and: '所有',
      or: '任意一个',
      operators: {
        like: "包含",
        not_like: "不包含",
        in: "属于",
        not_in: "不属于",
        gt: "大于",
        ge: "大于等于",
        lt: "小于",
        le: "小于等于",
        equals: "等于",
        not_equals: "不等于",
        between: "之间",
        current_user: "是当前用户"
      }
    }
  },
  license: {
    title: '授权管理',
    corporation: '客户名称',
    time: '授权时间',
    product: '产品名称',
    edition: '产品版本',
    licenseVersion: '授权版本',
    count: '授权数量',
    valid_license: '授权验证',
    show_license: '查看授权',
    valid_license_error: '授权验证失败',
    status: '授权状态',
    valid: '有效',
    invalid: '无效',
    expired: '已过期',
  },
  workspace: {
    create: '创建工作空间',
    update: '修改工作空间',
    delete: '删除工作空间',
    delete_confirm: '删除该工作空间会关联删除该工作空间下的所有资源(如：相关项目，测试用例等），确定要删除吗?',
    add: '添加工作空间',
    input_name: '请输入工作空间名称',
    search_by_name: '根据名称搜索',
    organization_name: '所属组织',
    please_choose_organization: '请选择组织',
    please_select_a_workspace_first: '请先选择工作空间！',
    none: '无工作空间',
    select: '选择工作空间',
    special_characters_are_not_supported: '格式错误(不支持特殊字符，且不能以\'-\'开头结尾)',
    delete_warning: '删除该工作空间将同步删除该工作空间下所有项目，以及项目中的所有用例、接口测试、性能测试等,确定要删除吗?',
  },
  organization: {
    create: '创建组织',
    modify: '修改组织',
    delete: '删除组织',
    delete_confirm: '删除该组织会关联删除该组织下的所有资源(如：相关工作空间，项目，测试用例等），确定要删除吗?',
    input_name: '请输入组织名称',
    select_organization: '请选择组织',
    search_by_name: '根据名称搜索',
    special_characters_are_not_supported: '格式错误(不支持特殊字符，且不能以\'-\'开头结尾)',
    none: '无组织',
    select: '选择组织',
    delete_warning: '删除该组织将同步删除该组织下所有相关工作空间和相关工作空间下的所有项目，以及项目中的所有用例、接口测试、性能测试等,确定要删除吗?',
    service_integration: '服务集成',
    defect_manage: '缺陷管理平台',
    message_settings: '消息设置',
    message: {
      jenkins_task_notification: 'Jenkins接口调用任务通知',
      test_plan_task_notification: '测试计划任务通知',
      test_review_task_notice: '测试评审任务通知',
      create_new_notification: '创建新通知',
      select_events: '选择事件',
      defect_task_notification: '缺陷任务通知',
      select_receiving_method: '选择接收方式',
      mail: '邮件',
      nail_robot: '钉钉机器人',
      enterprise_wechat_robot: '企业微信机器人',
      notes: '注意:1.钉钉和企业群里新建一个自定义机器人，然后复制 webhook 地址在我们平台上;\n' +
      '       2.机器人选择为群机器人，安全验证选择“自定义关键词” ："任务通知";\n' +
      '       3.选择接收人时必须是你所建的群里包含的人,接收人手机号为必填项且为钉钉企业所使用的手机号,',
      message: '事件，接收人，接收方式为必填项',
      message_webhook: '接收方式为钉钉和企业机器人时，webhook为必填项'


    },
    integration: {
      select_defect_platform: '请选择要集成的缺陷管理平台：',
      basic_auth_info: 'Basic Auth 账号信息：',
      api_account: 'API 账号',
      api_password: 'API 口令',
      jira_url: 'JIRA 地址',
      jira_issuetype: '问题类型',
      input_api_account: '请输入账号',
      input_api_password: '请输入口令',
      input_jira_url: '请输入Jira地址，例：https://qamp.atlassian.net/',
      input_jira_issuetype: '请输入问题类型',
      use_tip: '使用指引：',
      use_tip_tapd: 'Tapd Basic Auth 账号信息在"公司管理-安全与集成-开放平台"中查询',
      use_tip_jira: 'Jira software server 认证信息为 账号密码，Jira software cloud 认证信息为 账号+令牌(账户设置-安全-创建API令牌)',
      use_tip_two: '保存 Basic Auth 账号信息后，需要在 qamp 项目中手动关联 ID/key',
      link_the_project_now: '马上关联项目',
      cancel_edit: '取消编辑',
      cancel_integration: '取消集成',
      cancel_confirm: '确认取消集成 ',
      successful_operation: '操作成功',
      not_integrated: '未集成该平台',
      choose_platform: '请选择集成的平台',
      verified: '验证通过'
    }
  },
  project: {
    recent: '最近的项目',
    create: '创建项目',
    edit: '编辑项目',
    delete: '删除项目',
    delete_confirm: '确定要删除这个项目吗?',
    delete_tip: '删除该项目，会删除该项目下所有测试资源，确定要删除吗?',
    search_by_name: '根据名称搜索',
    input_name: '请输入项目名称',
    owning_workspace: '所属工作空间',
    please_choose_workspace: '请选择工作空间',
    special_characters_are_not_supported: '格式错误(不支持特殊字符，且不能以\'-\'开头结尾)',
    tapd_id: 'TAPD项目ID',
    jira_key: 'JIRA项目key',
  },
  member: {
    create: '添加成员',
    modify: '修改成员',
    delete_confirm: '这个用户确定要删除吗?',
    please_choose_member: '请选择成员',
    search_by_name: '根据名称搜索',
    modify_personal_info: '修改个人信息',
    edit_password: '修改密码',
    edit_information: '编辑信息',
    input_name: '请输入名称',
    input_email: '请输入邮箱',
    special_characters_are_not_supported: '不支持特殊字符',
    mobile_number_format_is_incorrect: '手机号码格式不正确',
    email_format_is_incorrect: '邮箱格式不正确',
    password_format_is_incorrect: '有效密码：8-30位，英文大小写字母+数字+特殊字符（可选）',
    old_password: '旧密码',
    new_password: '新密码',
    repeat_password: '确认密码',
    inconsistent_passwords: '两次输入的密码不一致',
    remove_member: '确定要移除该成员吗',
    input_id_or_email: '请输入用户 ID, 或者 用户邮箱',
    no_such_user: '无此用户信息, 请输入正确的用户 ID 或者 用户邮箱！',
  },
  user: {
    create: '创建用户',
    modify: '修改用户',
    input_name: '请输入用户姓名',
    input_id: '请输入ID',
    input_email: '请输入邮箱',
    input_password: '请输入密码',
    input_phone: '请输入电话号码',
    special_characters_are_not_supported: '不支持特殊字符',
    mobile_number_format_is_incorrect: '手机号码格式不正确',
    email_format_is_incorrect: '邮箱格式不正确',
    delete_confirm: '这个用户确定要删除吗?',
    apikey_delete_confirm: '这个 API Key 确定要删除吗?',
    input_id_placeholder: '请输入ID (只支持数字、英文字母)',
    source: '用户来源'
  },
  role: {
    please_choose_role: '请选择角色',
    admin: '系统管理员',
    org_admin: '组织管理员',
    test_manager: '测试经理',
    test_user: '测试人员',
    test_viewer: '只读用户',
    add: '添加角色',
  },
  report: {
    api_test_report: '接口测试报告',
    load_test_report: '性能测试报告',
    test_plan_report: '测试计划报告',
    recent: '我最近的报告',
    search_by_name: '根据名称搜索',
    test_name: '所属测试',
    test_overview: '测试概览',
    test_request_statistics: '请求统计',
    test_error_log: '错误记录',
    test_log_details: '日志详情',
    test_details: '测试详情',
    test_duration: '持续时间：{0} 分钟 {1} 秒',
    test_start_time: '开始时间',
    test_end_time: '结束时间',
    test_stop_now: '立即停止',
    test_stop_now_confirm: '确定要立即停止当前测试吗？',
    test_rerun_confirm: '确定要再次执行当前测试吗？',
    test_stop_success: '停止成功',
    test_execute_again: '再次执行',
    downloadJtl: '下载JTL',
    export: '导出',
    compare: '比较',
    generation_error: '报告生成错误, 无法查看, 请检查日志详情!',
    being_generated: '报告正在生成中...',
    delete_confirm: '确认删除报告: ',
    start_status: '测试处于开始状态, 我们生成报告后会自动展示到页面上！',
    run_status: '测试处于运行状态,请稍后查看报告！',
    user_name: '创建人',
    project_name: '所属项目',
    force_stop_tips: '<strong>强制停止</strong>测试会立刻结束当前测试并删除报告数据',
    stop_tips: '<strong>停止</strong>测试会结束当前测试并保留报告数据',
    force_stop_btn: '强制停止',
    stop_btn: '停止',
    not_exist: "测试报告不存在",
    batch_delete: "批量删除报告",
    delete_batch_confirm: '确认批量删除报告',
  },
  load_test: {
    same_project_test: '只能运行同一项目内的测试',
    already_exists: '测试名称不能重复',
    operating: '操作',
    recent: '我最近的测试',
    search_by_name: '根据名称搜索',
    project_name: '所属项目',
    delete_confirm: '确认删除测试: ',
    input_name: '请输入名称',
    select_project: '请选择项目',
    save_and_run: '保存并执行',
    basic_config: '场景配置',
    pressure_config: '压力配置',
    advanced_config: '高级配置',
    runtime_config: '运行配置',
    is_running: '正在运行！',
    test_name_is_null: '测试名称不能为空！',
    project_is_null: '项目不能为空！',
    jmx_is_null: '必需包含一个JMX文件，且只能包含一个JMX文件！',
    file_name: '文件名',
    file_size: '文件大小',
    file_type: '文件类型',
    file_status: '文件状态',
    last_modify_time: '修改时间',
    upload_tips: '将文件拖到此处，或<em>点击上传</em>',
    upload_type: '只能上传JMX/CSV/JAR文件',
    related_file_not_found: "未找到关联的测试文件！",
    delete_file_confirm: '确认删除文件: ',
    file_size_limit: "文件个数超出限制！",
    delete_file: "文件已存在，请先删除同名文件！",
    thread_num: '并发用户数：',
    input_thread_num: '请输入线程数',
    duration: '压测时长（秒）：',
    input_duration: '请输入时长',
    rps_limit: 'RPS上限：',
    input_rps_limit: '请输入限制',
    ramp_up_time_within: '在',
    ramp_up_time_minutes: '秒内，分',
    ramp_up_time_times: '次增加并发用户',
    advanced_config_error: '高级配置校验失败',
    domain_bind: '域名绑定',
    domain: '域名',
    enable: '是否启用',
    ip: 'IP地址',
    input_ip: '请输入正确的IP地址！',
    input_domain: '请输入正确的域名！',
    params: '自定义属性',
    param_name: '属性名',
    param_value: '属性值',
    domain_is_duplicate: '域名不能重复',
    param_is_duplicate: '参数名不能重复',
    domain_ip_is_empty: '域名和IP不能为空',
    param_name_value_is_empty: '参数名和参数值不能为空',
    connect_timeout: '建立连接超时时间',
    response_timeout: '响应超时时间',
    custom_http_code: '自定义 HTTP 响应成功状态码',
    separated_by_commas: '按逗号分隔',
    create: '创建测试',
    run: '一键运行',
    select_resource_pool: '请选择资源池',
    resource_pool_is_null: '资源池为空',
    download_log_file: '下载完整日志文件',
    pressure_prediction_chart: '压力预估图',
    user_name: '创建人',
    special_characters_are_not_supported: '测试名称不支持特殊字符',
    pressure_config_params_is_empty: '压力配置参数不能为空!',
    schedule_tip: '间隔时间不能小于压测时长'
  },
  api_test: {
    creator: "创建人",
    executor:"执行人",
    save_and_run: "保存并执行",
    run: "执行",
    running: "正在执行",
    reset: "重置",
    input_name: "请输入测试名称",
    select_project: "请选择项目",
    variable_name: "变量名",
    variable: "变量",
    copied: "已拷贝",
    key: "键",
    value: "值",
    create_performance_test: "创建性能测试",
    export_config: "导出",
    enable_validate_tip: "没有可用请求",
    copy: "复制测试",
    jar_config: {
      title: "jar包管理",
      jar_file: "jar包",
      delete_tip: "删除需重启服务后生效",
      file_exist: "该项目下已存在该jar包",
      upload_limit_size: "上传文件大小不能超过 30MB!",
    },
    environment: {
      name: "环境名称",
      socket: "环境域名",
      globalVariable: "全局变量",
      environment_list: "环境列表",
      environment_config: "环境配置",
      config_environment: "配置环境",
      environment: "环境",
      select_environment: "请选择环境",
      please_save_test: "请先保存测试",
      common_config: "通用配置",
      initial: "初始化配置",
      http_config: "HTTP配置",
      database_config: "数据库配置",
      tcp_config: "TCP配置",
    },
    scenario: {
      scenario: "场景",
      dubbo: "Dubbo配置",
      config: "场景配置",
      input_name: "请输入场景名称",
      name: "场景名称",
      base_url: "基础URL",
      base_url_description: "基础URL作为所有请求的URL前缀",
      variables: "自定义变量",
      headers: "请求头",
      kv_description: "所有请求可以使用自定义变量",
      copy: "复制场景",
      delete: "删除场景",
      disable: "禁用",
      enable: "启用",
      create_scenario: "创建新场景",
      select_scenario: "选择已有场景",
      scenario_request: "场景/请求",
      enable_disable: "启用/禁用",
      test_name: "测试名称",
      reference: "引用",
      clone: "复制",
      cant_reference: '历史测试文件，重新保存后才可被引用'
    },
    request: {
      debug: "调试",
      copy: "复制请求",
      delete: "删除请求",
      input_name: "请输入请求名称",
      input_url: "请输入请求URL",
      input_path: "请输入请求路径",
      name: "请求名称",
      content_type: "请求类型",
      method: "请求方法",
      url: "请求URL",
      path: "请求路径",
      address: "请求地址",
      refer_to_environment: "引用环境",
      please_configure_socket_in_environment: "请在环境中配置环境域名",
      please_configure_environment_in_scenario: "请在场景中配置环境",
      please_environment_to_scenario: "请先在场景中添加环境配置",
      url_description: "例如：https://fit2cloud.com",
      path_description: "例如：/login",
      url_invalid: "URL无效",
      parameters: "Query参数",
      jmeter_func: "Jmeter 方法",
      parameters_filter_example: "示例",
      parameters_filter_tips: "只支持 MockJs 函数结果预览",
      parameters_advance: "高级参数设置",
      parameters_preview: "预览",
      parameters_mock_filter_tips: "请输入关键字进行过滤",
      parameters_pre_request: "前置请求提取",
      parameters_advance_mock: "Mock 数据",
      parameters_advance_add_func: "添加函数",
      parameters_advance_add_func_limit: "最多支持5个函数",
      parameters_advance_add_func_error: "请先选择函数",
      parameters_advance_add_param_error: "请输入函数参数",
      parameters_desc: "参数追加到URL，例如https://fit2cloud.com/entries?key1=Value1&Key2=Value2",
      headers: "请求头",
      body: "请求体",
      body_kv: "键值对",
      body_text: "文本",
      timeout_config: "超时设置",
      connect_timeout: "连接超时",
      response_timeout: "响应超时",
      follow_redirects: "跟随重定向",
      do_multipart_post: "对 POST 使用 multipart/form-data",
      body_upload_limit_size: "上传文件大小不能超过 500 MB!",
      condition: "条件",
      condition_variable: "变量，例如: ${var}",
      wait: "等待",
      assertions: {
        label: "断言",
        text: "文本",
        regex: "正则",
        response_time: "响应时间",
        jsr223: "脚本",
        select_type: "请选择类型",
        select_subject: "请选择对象",
        select_condition: "请选择条件",
        contains: "包含",
        not_contains: "不包含",
        equals: "等于",
        start_with: "以...开始",
        end_with: "以...结束",
        value: "值",
        expect: "期望值",
        expression: "Perl型正则表达式",
        response_in_time: "响应时间在...毫秒以内",
        json_path_add: "添加 JONPATH 断言",
        json_path_err: "响应结果不是 JSON 格式",
        json_path_suggest: "推荐JSONPath断言",
        json_path_clear: "清空JSONPath断言",
        debug_first: "请先执行调试获取响应结果",
        ignore_status: "忽略状态",
        add: "添加",
        script_name: "脚本名称",
        script: "脚本",
        variable_name: "变量名称",
        set_failure_status: "设置失败状态",
        set_failure_msg: "设置失败消息",
      },
      extract: {
        label: "提取",
        multiple_matching: "匹配多条",
        select_type: "请选择类型",
        description: "从响应结果中提取数据并将其存储在变量中，在后续请求中使用变量。",
        regex: "正则",
        regex_expression: "Perl型正则表达式",
        json_path_expression: "JSONPath表达式",
        xpath_expression: "XPath表达式",
      },
      processor: {
        pre_exec_script: "预执行脚本",
        post_exec_script: "后执行脚本",
        code_template: "代码模版",
        bean_shell_processor_tip: "仅支持 BeanShell 脚本",
        code_template_get_variable: "获取变量",
        code_template_set_variable: "设置变量",
        code_template_get_global_variable: "获取全局变量",
        code_template_set_global_variable: "设置全局变量",
        code_template_get_response_header: "获取响应头",
        code_template_get_response_code: "获取响应码",
        code_template_get_response_result: "获取响应结果"
      },
      dubbo: {
        protocol: "协议",
        input_interface: "请输入Interface",
        input_method: "请输入Method",
        input_config_center: "请输入Config Center",
        input_registry_center: "请输入Registry Center",
        input_consumer_service: "请输入Consumer & Service",
        get_provider_success: "获取成功",
        check_registry_center: "获取失败，请检查Registry Center",
        form_description: "如果当前配置项无值，则取场景配置项的值",
      },
      sql: {
        dataSource: "数据源名称",
        sql_script: "SQL脚本",
        timeout: "超时时间(ms)",
        database_driver: "数据库驱动",
        database_url: "数据库连接URL",
        username: "用户名",
        password: "密码",
        pool_max: "最大连接数",
        query_timeout: "最大等待时间(ms)",
        name_cannot_be_empty: "SQL请求名称不能为空",
        dataSource_cannot_be_empty: "SQL请求数据源不能为空",
        result_variable: "存储结果",
        variable_names: "按列存储",
      },
      tcp: {
        server: "服务器名或IP",
        port: "端口",
        connect: "连接(ms)",
        response: "响应(ms)",
        re_use_connection: "Re-use connection",
        no_delay: "设置无延迟",
        close_connection: "关闭连接",
        so_linger: "SO LINGER",
        eol_byte: "行尾(EOL)字节值",
        request: "要发送的文本",
        username: "用户名",
        password: "密码",
        login: "登录设置",
        server_cannot_be_empty: "服务器名或IP不能为空",
      }
    },
    api_import: {
      label: "导入",
      title: "接口测试导入",
      data_format: "数据格式",
      file_size_limit: "文件大小不超过 20 M",
      tip: "说明",
      export_tip: "导出方法",
      ms_tip: "支持 qamp json 格式",
      ms_export_tip: "通过 qamp 接口测试页面或者浏览器插件导出 json 格式文件",
      postman_tip: "只支持 Postman Collection v2.1 格式的 json 文件",
      swagger_tip: "只支持 Swagger 2.x 版本" +
        "的 json 文件",
      post_export_tip: "通过 Postman 导出测试集合",
      swagger_export_tip: "通过 Swagger 页面导出",
      suffixFormatErr: "文件格式不符合要求",
      swagger_url_import: "使用URL导入",
    }
  },
  api_report: {
    title: "报告",
    request: "请求",
    request_body: "请求内容",
    request_headers: "请求头",
    request_cookie: "Cookie",
    response: "响应",
    delete_confirm: '确认删除报告: ',
    delete_batch_confirm: '确认批量删除报告',
    scenario_name: "场景名称",
    response_time: "响应时间(ms)",
    latency: "网络延迟",
    request_size: "请求大小",
    response_size: "响应大小",
    response_code: "状态码",
    response_message: "响应报文",
    error: "错误",
    assertions: "断言",
    assertions_pass: "成功断言",
    assertions_name: "断言名称",
    assertions_error_message: "错误信息",
    assertions_is_success: "是否成功",
    result: "结果",
    success: "成功",
    fail: "失败",
    total: "全部",
    test_name: "所属测试",
    request_result: "请求结果",
    sub_result: "子请求",
    detail: "报告详情",
    delete: "删除报告",
    batch_delete: "批量删除报告",
    running: "测试执行中",
    not_exist: "测试报告不存在",
  },
  test_track: {
    test_track: "测试管理",
    confirm: "确 定",
    cancel: "取 消",
    project: "项目",
    save: "保 存",
    return: "返 回",
    length_less_than: "长度必须小于",
    recent_plan: "最近的计划",
    recent_case: "最近的用例",
    recent_review: "最近的评审",
    pass_rate: "通过率",
    execution_result: ": 请选择执行结果",
    actual_result: ": 实际结果为空",

    case: {
      script_id: '脚本ID',
      story_id: 'StoryId',
      sprintId: 'Sprint ID',
      sprintName: 'Sprint名称',
      export_all_cases: '确定要导出全部用例吗?',
      input_test_case: '请输入关联用例名称',
      test_name: '测试名称',
      other: "--其他--",
      test_case: "测试用例",
      move: "移动用例",
      case_list: "用例列表",
      create_case: "创建用例",
      edit_case: "编辑用例",
      view_case: "查看用例",
      no_project: "该工作空间下无项目，请先创建项目",
      priority: "用例等级",
      type: "类型",
      method: "测试方式",
      auto: "自动",
      manual: "手动",
      create: "新建用例",
      case_type: "用例类型",
      name: "用例名称",
      module: "所属模块",
      maintainer: "维护人",
      tags:"用例标签",
      steps: "执行步骤",
      number: "编号",
      prerequisite: "前置条件",
      step_desc: "步骤描述",
      expected_results: "预期结果",
      input_name: "请输入名称",
      input_module: "请选择模块",
      input_maintainer: "请选择维护人",
      input_priority: "请选择用例等级",
      input_type: "请选择用例类型",
      input_method: "请选择测试方式",
      input_prerequisite: "请输入前置条件",
      delete_confirm: "确认删除测试用例",
      delete: "删除用例",
      save_create_continue: "保存并继续创建",
      please_create_project: "暂无项目，请先创建项目",
      create_module_first: "请先新建模块",
      relate_test: "关联测试",
      relate_test_not_find: '关联的测试不存在,请检查用例',
      other_relate_test_not_find: '关联的测试名，请前往第三方平台执行',
      batch_handle: '批量处理 (选中{0}项)',
      batch_update: '更新{0}个用例的属性',
      select_catalog: '请选择用例目录',
      updated_attr_value: '更新后属性值为',
      batch_operate: '批量操作',
      please_select_attr: '请选择属性',
      please_select_attr_value: '请选择属性对应的值',
      batch_edit_case: '批量编辑用例',
      batch_move_case: '批量移动用例',
      batch_copy_case: '批量复制用例',
      batch_delete_case: '批量删除用例',
      batch_unlink: '批量取消关联',
      project_name: '所属项目',
      status: '评审状态',
      status_prepare: '未评审',
      status_pass: '通过',
      status_un_pass: '未通过',
      cancel_relevance_project: "取消项目关联会同时取消该项目下已关联的测试用例",
      img_loading_fail: "图片加载失败",
      pdf_loading_fail: "PDF加载失败",
      upload_tip: "只能上传jpg、jpeg、png、docx、doc、pdf、xlsx文件",
      attachment: "附件",
      upload_time: "上传时间",
     tagType: {
      auto:"自动化候选",
      exceptional:"异常用例",
      upgrade:"升级用例",
      regression:"回归用例",
      e2e:"E2E",
      main:"主场景",
      smoke:"线上冒烟",
      dev:"开发自测",
      improve:"待完善",
      },
      import: {
        import: "导入用例",
        case_import: "导入测试用例",
        download_template: "下载模版",
        click_upload: "点击上传",
        upload_limit: "只能上传xls/xlsx文件，且不超过20M",
        upload_xmind: "支持文件类型：.xmind；一次至多导入500 条用例",
        upload_xmind_format: "上传文件只能是 .xmind 格式",
        upload_limit_other_size: "上传文件大小不能超过",
        upload_limit_count: "一次只能上传一个文件",
        upload_limit_format: "上传文件只能是 xls、xlsx格式!",
        upload_limit_size: "上传文件大小不能超过 20MB!",
        success: "导入成功！",
        importing: "导入中...",
        excel_title: "表格文件",
        xmind_title: "思维导图",
        import_desc: "导入说明",
        import_file: "上传文件",
      },
      export: {
        export: "导出用例"
      },
      script: {
        title: '关联脚本',
        name: '脚本名称',
        class: '类名',
        method: '方法名',
      },
      story: {
        title: "关联STORY",
      },
      sprint: {
        title: "关联Sprint",
      },
      synScript: {
              title: "同步自动化脚本",
            }
    },

    plan: {
      test_plan: "测试计划",
      plan_task: "任务中心",
      create_plan: "创建测试计划",
      edit_plan: "编辑测试计划",
      plan_name: "计划名称",
      plan_tag: "计划标签",
      plan_project: "所属项目",
      plan_stage: "测试阶段",
      plan_status: "当前状态",
      smoke_test: "冒烟测试",
      functional_test: "功能测试",
      regression_test: "回归测试",
      cross_test: "交叉测试",
      develop_test: "开发自测",
      integration_testing: "集成测试",
      system_test: "新功能测试",
      version_validation: "版本验证",
      plan_version: "版本",
      plan_principal: "负责人",
      input_plan_name: "请输入测试计划名称",
      input_plan_principal: "请选择负责人",
      input_plan_project: "请选择所属项目",
      input_plan_stage: "请选择测试阶段",
      input_plan_version: "请选择版本",
      plan_status_prepare: "未开始",
      plan_status_running: "进行中",
      plan_status_completed: "已完成",
      planned_start_time: "计划开始",
      planned_end_time: "计划结束",
      actual_start_time: "实际开始",
      actual_end_time: "实际结束",
      plan_delete_confirm: "将删除该测试计划下所有用例，确认删除测试计划: ",
      plan_delete: "删除计划",
      plan_duplicate: "复制计划",
    },
    review: {
            test_review: "用例评审",
            create_review: "创建用例评审",
            edit_review: "编辑用例评审",
            review_name: "评审名称",
            reviewer: "评审人",
            review_project: "所属项目",
            review_creator: "发起人",
            review_status: "当前状态",
            end_time: "截止时间",
            delete: "删除评审",
            input_review_name: "请输入评审名称",
            input_review_project: "请选择所属项目",
            input_reviewer: "请选择评审人",
            no_link_case: "没有关联用例！",
            pass: "通过",
            un_pass: "未通过",
            comment: "评论",
            my_review: "我的评审",
            my_create: "我创建的评审",
            reviewed_by_me: "待我评审",
            creator: "创建人",
            done: "已评用例",
            result_distribution: "结果分布",
            input_storyId: "请输入jira号，如XP-34775",
            review_storyId: "关联jira",
          },
    review_view: {
      review: "评审",
      all_case: "全部用例",
      start_review: "开始评审",
      relevance_case: "关联用例",
      last_page: "已经到底了！",
      execute_result: "评审结果",
    },
    record: {
          test_record: "流量回放",
          request_id: "请求编号",
          request_type: "请求类型",
          request_url: "请求URL",
          request_method: "请求方式",
          request_body: "请求报文",
          request_message: "返回消息",
          delete: "删除请求记录",
          create:"创建流量回放",
          input_name:"请输入任务名称",
          name:"流量任务",
          service:"服务"
        },
    module: {
      search: "搜索模块",
      rename: "重命名",
      add_submodule: "添加子模块",
      add_module: "添加模块",
      name: "模块名称",
      delete_confirm: "确认删除模块: ",
      delete_all_resource: "以及模块下所有子模块和测试用例",
      module: "模块",
      title: "标题",
      status: "状态",
      describe: "描述",
      current_owner: "处理人",
      creation_time: "创建时间",
      project_name: "所属项目"
    },
    home: {
      recent_test: "最近测试",
      my_plan: "我的计划",
      test_rate: "测试进度",
      tested_case: "已测用例",
    },
    plan_view: {
      plan: "计划",
      relevance_test_case: "关联用例",
      cancel_all_relevance: "取消全部关联",
      executor: "执行人",
      execute_result: "执行结果",
      execute_date: "执行日期",
      execute_log: "执行日志",
      batchId: "BatchId",
      test_history: "历史结果",
      pass: "通过",
      failure: "失败",
      blocking: "阻塞",
      skip: "跳过",
      actual_result: "实际结果",
      step_result: "步骤执行结果",
      my_case: "我的用例",
      all_case: "全部用例",
      pre_case: "上一条用例",
      next_case: "下一条用例",
      change_execution_results: "更改执行结果",
      change_executor: "更改执行人",
      select_executor: "请选择执行人",
      select_execute_result: "选择执行结果",
      cancel_relevance: "取消用例关联",
      confirm_cancel_relevance: "确认取消关联",
      select_manipulate: "请选择需要操作的数据",
      select_template: "选择模版",
      step: "步骤",
      submit_issues: "提缺陷",
      operate_step: "操作步骤",
      edit_component: "编辑组件",
      base_info: "基础信息",
      test_result: "测试结果",
      result_distribution: "测试结果分布",
      custom_component: "自定义模块",
      defect_list: "缺陷列表",
      create_report: "创建测试报告",
      view_report: "查看测试报告",
      component_library: "组件库",
      component_library_tip: "拖拽组件库中组件，添加至右侧，预览报告效果，每个系统组件只能添加一个。",
      delete_component_tip: "请至少保留一个组件",
      input_template_name: "输入模版名称",
      template_special_characters: '模版名称不支持特殊字符',
      case_count: "用例数",
      issues_count: "缺陷数",
      result_statistics: "测试结果统计",
      result_statistics_chart: "测试结果统计图",
      create_template: "新建模版",
      report_template: "测试报告模版",
      test_detail: "测试详情",
      failure_case: "失败用例",
      export_report: "导出报告"
    },
    issue: {
      issue: "缺陷",
      platform_tip: "在系统设置-组织-服务集成中集成缺陷管理平台可以自动提交缺陷到指定缺陷管理平台",
      input_title: "请输入标题",
      id: "缺陷ID",
      title: "缺陷标题",
      description: "缺陷描述",
      status: "缺陷状态",
      platform: "平台",
      operate: "操作",
      close: "关闭缺陷",
      title_description_required: "标题和描述必填",
      close_success: "关闭成功",
      preview: "预览"
    }
  },
  test_resource_pool: {
    type: '类型',
    enable_disable: '启用/禁用',
    search_by_name: '根据名称搜索',
    create_resource_pool: '创建资源池',
    update_resource_pool: '修改资源池',
    select_pool_type: '选择资源类型',
    max_threads: '最大并发数',
    input_pool_name: '请输入资源池名称',
    pool_name_valid: '资源池名称不支持特殊字符',
    cannot_remove_all_node: '不能删除所有独立节点',
    cannot_empty: '资源池不能为空',
    fill_the_data: '请完善数据',
    delete_prompt: '此操作将永久删除该资源池, 是否继续?',
    status_change_success: '状态修改成功!',
    status_change_failed: '状态修改失败, 校验不通过!',
    check_in: '校验中',
  },
  system_parameter_setting: {
    mailbox_service_settings: '邮件设置',
    ldap_setting: 'LDAP设置',
    test_connection: '测试连接',
    SMTP_host: 'SMTP主机',
    SMTP_port: 'SMTP端口',
    SMTP_account: 'SMTP账户',
    SMTP_password: 'SMTP密码',
    SSL: '开启SSL(如果SMTP端口是465，通常需要启用SSL)',
    TLS: '开启TLS(如果SMTP端口是587，通常需要启用TLS)',
    SMTP: '是否匿名 SMTP',
    host: '主机号不能为空',
    port: '端口号不能为空',
    account: '账户不能为空',
  },
  i18n: {
    home: '首页'
  },
  ldap: {
    url: 'LDAP地址',
    dn: '绑定DN',
    password: '密码',
    ou: '用户OU',
    filter: '用户过滤器',
    mapping: 'LDAP属性映射',
    open: '启用LDAP认证',
    input_url: '请输入LDAP地址',
    input_dn: '请输入DN',
    input_password: '请输入密码',
    input_ou: '请输入用户OU',
    input_filter: '请输入用户过滤器',
    input_mapping: '请输入LDAP属性映射',
    input_username: '请输入用户名',
    input_url_placeholder: '请输入LDAP地址 (如 ldap://localhost:389)',
    input_ou_placeholder: '输入用户OU (使用|分隔各OU)',
    input_filter_placeholder: '输入过滤器 [可能的选项是cn或uid或sAMAccountName={0}, 如：(uid={0})]',
    input_mapping_placeholder: '如：{"username":"uid","name":"sn","email":"mail"}, username映射的选项可能是cn或uid或sAMAccountName',
    test_connect: '测试连接',
    test_login: '测试登录',
    edit: '编辑',
    login_success: '登录成功',
    url_cannot_be_empty: 'LDAP 地址不能为空',
    dn_cannot_be_empty: 'LDAP DN不能为空',
    ou_cannot_be_empty: 'LDAP OU不能为空',
    filter_cannot_be_empty: 'LDAP 用户过滤器不能为空',
    mapping_cannot_be_empty: 'LDAP 用户属性映射不能为空',
    password_cannot_be_empty: 'LDAP 密码不能为空',
  },
  schedule: {
    input_email: "请输入邮箱账号",
    event: "事件",
    event_success: '执行成功',
    event_failed: '执行失败',
    receiving_mode: "接收方式",
    receiver: "接收人",
    operation: "操作",
    task_notification: "任务通知",
    not_set: "未设置",
    test_name: '测试名称',
    running_rule: '运行规则',
    job_status: '任务状态',
    running_task: '运行中的任务',
    next_execution_time: "下次执行时间",
    edit_timer_task: "编辑定时任务",
    please_input_cron_expression: "请输入 Cron 表达式",
    generate_expression: "生成表达式",
    cron_expression_format_error: "Cron 表达式格式错误",
    cron_expression_interval_short_error: "间隔时间小于 3 分钟, 请避免执行耗时过长的测试",
    cron: {
      seconds: "秒",
      minutes: "分钟",
      hours: "小时",
      day: "日",
      month: "月",
      weeks: "周",
      years: "年",
      week: "星期",
      time_expression: "时间表达式",
      complete_expression: "完整表达式",
      allowed_wildcards: "允许的通配符[, - * /]",
      day_allowed_wildcards: "允许的通配符[, - * / L M]",
      weeks_allowed_wildcards: "允许的通配符[, - * / L M]",
      not_specify: "不指定",
      specify: "指定",
      period: "周期",
      from: "从",
      every: "每",
      day_unit: "号",
      start: "开始",
      execute_once: "执行一次",
      last_working_day: "最近的那个工作日",
      last_day_of_the_month: "本月最后一天",
      multi_select: "可多选",
      num: "第",
      week_of_weeks: "周的星期",
      last_week_of_the_month: "本月最后一个星期",
      not_fill: "不填",
      recent_run_time: "最近5次运行时间",
      no_qualifying_results: "没有达到条件的结果",
    }
  },
  quota: {
    default: {
      organization: "组织默认配额",
      workspace: "工作空间默认配额",
    },
    api: "接口测试数量",
    performance: "性能测试数量",
    resource_pool: "可用测试资源池",
    max_threads: "最大并发数",
    duration: "压测时长（分钟）",
    use_default: "使用默认配额",
    yes: "是",
    no: "否",
    edit: "编辑",
    list: "配额列表",
    modify: "修改配额",
    edit_quota_title: "{0}的配额",
    workspace_quota_list: "{0}的工作空间配额列表",
    unlimited: "无限制",
    clean: "清空"
  }
};
